import { render, staticRenderFns } from "./_unpersonalizedInviteUuid.vue?vue&type=template&id=1384c2cd&scoped=true"
import script from "./_unpersonalizedInviteUuid.vue?vue&type=script&lang=ts"
export * from "./_unpersonalizedInviteUuid.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1384c2cd",
  null
  
)

export default component.exports