
import Vue from 'vue'
import { Context } from '@nuxt/types'
import { handleError } from '@/shared/utils/handleError'
import UnpersonalizedCommunityInviteComponent from '@/bookingbuddy-shop/components/communities/UnpersonalizedCommunityInvite.vue'
import { UnpersonalizedCommunityInvite } from '@/shared/jsonapi-orm/bookingbuddy/UnpersonalizedCommunityInvite'
export default Vue.extend({
  name: 'UnpersonalizedCommunityInviteDetail',
  components: {
    UnpersonalizedCommunityInviteComponent,
  },
  async asyncData(ctx: Context) {
    if (ctx.query.shouldLogin && !ctx.$authService.account) {
      let query = { ...ctx.route.query }
      delete query.shouldLogin
      let targetPath = ctx.app.localePath({
        name: String(ctx.app.getRouteBaseName(ctx.route)),
        params: { ...ctx.route.params },
        query: query,
      }) as string
      return ctx.redirect(
        ctx.app.localePath({
          name: 'login',
          query: {
            returnTo: ctx.$config.baseUrl + targetPath,
            target: targetPath,
            asProvider: 'true',
          },
        })
      )
    }
    try {
      const invite = await UnpersonalizedCommunityInvite.api(
        ctx.$jsonApiService
      )
        .with(['community'])
        .find(ctx.params.unpersonalizedInviteUuid)

      invite.$destruct()
    } catch (e) {
      handleError(ctx, e)
    }
  },
  data() {
    const invite = UnpersonalizedCommunityInvite.fromId(
      this.$route.params.unpersonalizedInviteUuid,
      this.$jsonApiService
    )
    return {
      invite: invite,
      token: this.$route.query.token ?? '',
    }
  },
})
