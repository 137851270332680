
import Vue, { PropType } from 'vue'
import { UnpersonalizedCommunityInvite } from '@/shared/jsonapi-orm/bookingbuddy/UnpersonalizedCommunityInvite'
import dayjs from 'dayjs'
import LoadingIcon from '@/shared/components/partials/LoadingIcon.vue'
import BaseButton from '@/shared/components/buttons/BaseButton.vue'
import IconWithBackground from '@/shared/components/utils/IconWithBackground.vue'

export default Vue.extend({
  name: 'UnpersonalizedCommunityInvite',
  components: { IconWithBackground, BaseButton, LoadingIcon },
  props: {
    invite: {
      type: Object as PropType<UnpersonalizedCommunityInvite>,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      successfulAccepted: false,
    }
  },
  computed: {
    isExpired(): boolean {
      return dayjs().isAfter(this.invite.expiry)
    },
    /**
     * Build register url
     * with pre-filled data
     */
    registerPath(): string {
      // build target path with current route
      // add shouldLogin query for auto-login after registration
      let targetPath = this.localePath({
        name: String(this.getRouteBaseName(this.$route)),
        params: { ...this.$route.params },
        query: {
          ...this.$route.query,
          shouldLogin: '1',
        },
      }) as string

      return this.localePath({
        name: 'register',
        query: {
          target: targetPath,
          asProvider: 'false',
        },
      })
    },
    loginPath(): string {
      return this.localePath({
        name: 'login',
        query: {
          returnTo: this.$config.baseUrl + this.$route.fullPath,
          target: this.$route.fullPath,
          asProvider: 'false',
        },
      })
    },
    alreadyMember(): boolean {
      return (
        this.$authService.account?.communities?.some(
          (c) => c.slug === this.invite.community?.slug
        ) ?? false
      )
    },
    /**
     * Build url which logs out first
     * and then redirects to login with target for current invite
     */
    changeUserPath(): string {
      const loginUrl = this.$config.baseUrl + this.loginPath
      return this.localePath({
        name: 'logout',
        query: {
          target: loginUrl,
        },
      })
    },
    logoutAndRegisterPath(): string {
      const registerUrl = this.$config.baseUrl + this.registerPath
      return this.localePath({
        name: 'logout',
        query: {
          target: registerUrl,
        },
      })
    },
    communityExplorePath(): string {
      return this.localePath({
        name: 'explore-c-communitySlug',
        params: { communitySlug: this.invite.community!.slug },
      })
    },
  },
  methods: {
    async acceptInvite() {
      let success = await this.invite.accept(false, this.token)

      if (!success) {
        return
      }

      this.successfulAccepted = true
      await this.$authService.account?.load('communities')
      await this.$store.dispatch('ux/pushNotification', {
        type: 'success',
        html: this.$t('pages.communityInvites.joinedSuccessfully', {
          community: this.invite.community!.name,
        }),
      })
      await this.$router.push(this.communityExplorePath)
    },
  },
})
